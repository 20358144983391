<template>
  <div>
    <a-spin size="large" :spinning="spinning">
      <a-page-header :title="title" @back="goBack" />
      <div class="add_box" style="border: 1px solid #cccccc;padding: 20px;overflow: hidden;">
        <a-tabs v-model="activeKey" @change="callback">
          <a-tab-pane key="1" tab="企业基本信息">
            <a-form-model :model="form" ref="ruleForm" :rules="rules" :label-col="{ span: 4 }"
              :wrapper-col="{ span: 14 }">
              <a-form-model-item style="width: 1000px; margin: 0 auto;" label="logo" prop="enterprise_logo_url"
                :wrapper-col="{ span: 12 }">
                <a-upload list-type="picture-card" :action="IMG_API + '/oss/files'" :before-upload="beforeUpload"
                  @change="uploadEnterLogo" :remove="removeLogo" :disabled="isLook() || isHideLogo"
                  :show-upload-list="false">
                  <img v-if="form.enterprise_logo_url" :src="form.enterprise_logo_url"
                    style="width: 86px; height: 86px" />
                  <div v-else>
                    <a-icon :type="loading ? 'loading' : 'plus'" />
                    <div>上传企业LOGO</div>
                  </div>
                </a-upload>
              </a-form-model-item>
              <!-- <div class="info">基本信息</div> -->
              <a-form-model-item style="width: 1000px; margin: 0 auto 15px;" label="企业名称" prop="enterprise_name">
                <a-input v-model="form.enterprise_name" placeholder="请输入企业名称" :disabled="true" />
              </a-form-model-item>
              <a-form-model-item style="width: 1000px; margin: 15px auto;" label="企业类型" prop="enterprise_type">
                <a-select v-model="form.enterprise_type" placeholder="请选择" @change="handleEnterpriseTypeChange"
                  :disabled="isLook()" :getPopupContainer="(triggerNode) => triggerNode.parentNode">
                  <a-select-option v-for="item in enterpriseTypes" :key="item.enterprise_type_id"
                    :value="item.enterprise_type_id">
                    {{ item.enterprise_type_name }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
              <a-form-model-item style="width: 1000px; margin: 15px auto;" label="统一社会信用代码" prop="us_code">
                <a-input :value="form.us_code" placeholder="请输入统一社会信用代码" :disabled="true"
                  @input="(e) => (form.us_code = validAllowCharNum(e))" />
              </a-form-model-item>
              <a-form-model-item style="width: 1000px; margin: 15px auto;" label="行业分类" prop="industry_id">
                <a-cascader :options="industryTree" expand-trigger="hover"
                  :getPopupContainer="(triggerNode) => triggerNode.parentNode" v-model="parent_industry_id_list"
                  :fieldNames="fieldNames2" placeholder="请选择行业分类" @change="selectIndustry" :disabled="true" />
              </a-form-model-item>
              <a-form-model-item style="width: 1000px; margin: 15px auto;" label="是否孵化器内企业" prop="is_in_incubator">
                <a-radio-group :disabled="isLook()" v-model="form.is_in_incubator">
                  <a-radio :value="1">
                    是
                  </a-radio>
                  <a-radio :value="0">
                    否
                  </a-radio>
                </a-radio-group>
              </a-form-model-item>
              <a-form-model-item v-if="false" style="width: 1000px; margin: 15px auto;" label="企业性质"
                prop="enterprise_nature">
                <a-checkbox-group @change="selectedEnterpriseType" :disabled="isLook()" :value="form.enterprise_nature">
                  <a-checkbox value="1">
                    国企
                  </a-checkbox>
                  <a-checkbox value="2">
                    央企
                  </a-checkbox>
                  <a-checkbox value="3">
                    集体性质
                  </a-checkbox>
                  <a-checkbox value="4">
                    民营
                  </a-checkbox>
                  <a-checkbox value="5">
                    外资
                  </a-checkbox>
                  <div>
                    <a-checkbox value="100">
                      社会组织
                    </a-checkbox>
                    <div style="margin: 5px 0;" v-if="showSocietyOrganizationInput">
                      <a-form-model-item prop="social_organization">
                        <a-input placeholder="请输入社会组织名称" v-model.trim="form.social_organization"
                          :disabled="isLook()"></a-input>
                      </a-form-model-item>
                    </div>
                  </div>
                  <div>
                    <a-checkbox value="101">
                      其他
                    </a-checkbox>
                    <div style="margin: 5px 0;" v-if="showOtherInput">
                      <a-form-model-item prop="nature_other">
                        <a-input placeholder="请输入企业性质名称" v-model.trim="form.nature_other"
                          :disabled="isLook()"></a-input>
                      </a-form-model-item>
                    </div>
                  </div>
                </a-checkbox-group>
              </a-form-model-item>

              <a-form-model-item style="width: 1000px; margin: 15px auto;" label="企业简介" prop="enter_profile">
                <!-- <a-textarea placeholder="请输入企业简介" v-model="form.enter_profile" :rows="4" :disabled="isLook()" /> -->
                <div id="editer" class="editor"></div>
              </a-form-model-item>
              <!-- 新增内容 -->
              <a-form-model-item style="width: 1000px; margin: 15px auto;" label="关联企业">
                <a-select :disabled="isLook()" show-search v-model="form.enterpriseIdList" mode="multiple"
                  placeholder="请输入企业名称" :getPopupContainer="(triggerNode) => triggerNode.parentNode"
                  :default-active-first-option="false" :show-arrow="false" :filter-option="false"
                  :not-found-content="null" @search="getGroupDown">
                  <a-select-option v-for="d in enterpriseList" :key="d.enterprise_id">
                    {{ d.enterprise_name }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
              <a-form-model-item style="width: 1000px; margin: 15px auto;" label="企业联系人" prop="enter_link_person">
                <a-input :value="form.enter_link_person" placeholder="请输入企业联系人" :disabled="isLook()"
                  @input="(e) => (form.enter_link_person = nameVerify(e).trim())" />
              </a-form-model-item>

              <a-form-model-item style="width: 1000px; margin: 15px auto;" label="企业联系人电话" prop="phone">
                <a-input v-model.trim="form.phone" placeholder="请输入企业联系人电话" :disabled="isLook()" />
              </a-form-model-item>
              <a-form-model-item style="width: 1000px; margin: 0 auto;" label="营业执照">
                <a-upload list-type="picture-card" :action="IMG_API + '/oss/files'" :before-upload="beforeUpload"
                  @change="uploadBusinessLicense" @remove="removeBusinessLicense" :disabled="isLook() || isHideBusiness"
                  :show-upload-list="false">
                  <img v-if="form.business_license" :src="form.business_license" style="width: 102px; height: 102px" />
                  <div v-else>
                    <a-icon :type="loading ? 'loading' : 'plus'" />
                    <div>上传营业执照</div>
                  </div>
                </a-upload>
              </a-form-model-item>


              <a-form-model-item style="width: 1000px; margin: 0 auto;" label="法人身份照(人像面)">
                <a-upload list-type="picture-card" :action="IMG_API + '/oss/files'" :before-upload="beforeUpload"
                  @change="uploadIdCardPortrait" @remove="removeIdCardPortrait" :disabled="isLook() || isHidePortrait"
                  :show-upload-list="false">
                  <img v-if="form.id_card_portrait" :src="form.id_card_portrait" style="width: 102px; height: 102px" />
                  <div v-else>
                    <a-icon :type="loading ? 'loading' : 'plus'" />
                    <div>上传法人身份照(人像面)</div>
                  </div>
                </a-upload>
              </a-form-model-item>


              <a-form-model-item style="width: 1000px; margin: 0 auto;" label="法人身份照(国徽面)">
                <a-upload list-type="picture-card" :action="IMG_API + '/oss/files'" :before-upload="beforeUpload"
                  @change="uploadIdCardNational" @remove="removeIdCardNational" :disabled="isLook() || isHideNational"
                  :show-upload-list="false">
                  <img v-if="form.id_card_national" :src="form.id_card_national" style="width: 102px; height: 102px" />
                  <div v-else>
                    <a-icon :type="loading ? 'loading' : 'plus'" />
                    <div>上传法人身份证(国徽面)</div>
                  </div>
                </a-upload>
              </a-form-model-item>

              <a-form-model-item style="width: 1000px; margin: 0 auto;" label="企业其他信息" prop="enterprise_remark">
                <a-textarea :maxLength="500" v-model.trim="form.enterprise_remark" :rows="4"
                  placeholder="请输入备注，不超过500字符" :disabled="isLook()" />
              </a-form-model-item>

            </a-form-model>
          </a-tab-pane>
          <a-tab-pane key="2" tab="企业入驻信息">
            <!-- 查看状态下 按钮 及弹框 都不展示 -->
            <!-- <div class="confirmJoin" v-if="!isLook()">
              <a-button type="primary" @click="evokeModal('新增')">新增入驻信息</a-button>
            </div> -->

            <a-modal :maskClosable="false" v-model="showModal" :title="`${modalTitle}入驻信息`" @ok="confirmJoin"
              :closable="false" @cancel="handleCancel">
              <a-form-model :model="joinForm" ref="joinForm" :rules="rules" :label-col="{ span: 5 }"
                :wrapper-col="{ span: 19 }">
                <a-form-model-item v-show="modalTitle === '新增'" label="入驻项目" prop="address_id">
                  <a-select size="large" style="height: 40px;" v-model="joinForm.address_id" placeholder="请选择入驻空间"
                    @change="handleSelectAddress"
                    :getPopupContainer="(triggerNode) => (triggerNode.parentNode || document.body)">
                    <a-select-option v-for="(item, index) in address" :key="index" :value="item.id">
                      {{ item.project_name }}
                    </a-select-option>
                  </a-select>
                  <!-- <a-cascader expandTrigger="hover" style="width: 100%;height: 40px;" v-model="joinForm.address_id"
                    :getPopupContainer="(triggerNode) => triggerNode.parentNode" :options="address"
                    @change="handleSelectAddress"
                    :fieldNames="{ label: 'project_name', children: 'child', value: 'id' }" placeholder="请选择入驻空间" /> -->
                </a-form-model-item>
                <a-form-model-item v-if="joinForm.address_id || modalTitle !== '新增'" label="入驻空间:" prop="selectIds">
                  <el-cascader style="width: 100%;" v-show="modalTitle === '新增'" ref="cascader" @change="onSpaceChange"
                    :options="options" v-model="joinForm.selectIds" :props="props" collapse-tags
                    clearable></el-cascader>
                  <span v-show="modalTitle !== '新增'">{{ joinForm.selectNames }}</span>
                </a-form-model-item>
                <a-form-model-item label="入驻有效日期" prop="joinProjectDate">
                  <a-range-picker size="large"  v-show="modalTitle === '新增'" @change="selectedJoinDate" v-model="joinForm.joinProjectDate"
                    style="width: 100%" :getCalendarContainer="(triggerNode) => triggerNode.parentNode"
                    :allowClear="false" />
                    <span v-show="modalTitle !== '新增'">{{joinForm.start_at?moment(joinForm.start_at).format('YYYY-MM-DD'):''  }} - {{joinForm.end_at?moment(joinForm.end_at).format('YYYY-MM-DD'):'' }}</span>
                </a-form-model-item>
                <a-form-model-item label="企服管家" prop="user_id">
                  <a-select size="large" v-model="joinForm.user_id" placeholder="请选择企服管家" @change="handleSelect"
                    :getPopupContainer="(triggerNode) => (triggerNode.parentNode || document.body)">
                    <a-select-option v-for="(item, index) in commissionerData" :key="index" :value="item.user_id">
                      {{ item.name }}
                    </a-select-option>
                  </a-select>
                </a-form-model-item>
              </a-form-model>
            </a-modal>
            <div v-if="joinPorjectData.length" class="info">待新增入驻信息</div>
            <a-table v-if="joinPorjectData.length" :columns="joinPorjectcolumns" :data-source="joinPorjectData" bordered
              :pagination="false">
              <template slot="projectName" slot-scope="text, record">
                <div>
                  {{ `${record.project_name}${record.parent_name_string ? '/' + record.parent_name_string :
                    ''}${record.space_name
                      ? '/' + record.space_name : ''}` }}
                </div>
              </template>
              <template slot="operation" slot-scope="text, record" v-if="editState !== '2'">
                <!-- <a-button v-if="joinPorjectData.length && editState !== '2'" type="link"
                  @click="evokeModal('编辑', record)">
                  编辑</a-button> -->
                <a-popconfirm v-if="joinPorjectData.length" title="确定要删除这条数据吗?" @confirm="() => onDelete(record)">
                  <a-button style="color: red;" type="link">删除</a-button>
                </a-popconfirm>
              </template>
            </a-table>

            <div v-if="editjoinPorjectData.length" class="info">企业入驻信息</div>
            <a-table v-if="editjoinPorjectData.length" :columns="joinPorjectcolumns" :data-source="editjoinPorjectData"
              bordered :pagination="false">
              <template slot="projectName" slot-scope="text, record">
                <div>
                  {{ `${record.project_name}${record.parent_name_string ? '/' + record.parent_name_string :
                    ''}${record.space_name
                      ? '/' + record.space_name : ''}` }}
                </div>
              </template>
              <template slot="operation" slot-scope="text, record" v-if="editState !== '2'">
                <a-button v-if="editjoinPorjectData.length && editState !== '2'" type="link"
                  @click="evokeModal('编辑', record)">
                  编辑</a-button>
              </template>
            </a-table>

            <div v-if="editState !== '0' && leasePorjectData.length" class="info">退租信息</div>

            <a-table v-if="leasePorjectData.length" :columns="leasePorjectcolumns" :data-source="leasePorjectData"
              bordered :pagination="false">
              <template slot="projectName" slot-scope="text, record">
                <div>
                  {{ `${record.project_name}${record.parent_name_string ? '/' + record.parent_name_string :
                    ''}${record.space_name
                      ? '/' + record.space_name : ''}` }}
                </div>
              </template>
            </a-table>
          </a-tab-pane>
          <a-tab-pane v-if="false" key="3" tab="企业经营信息" force-render>

            <a-form-model :model="form" :label-col="{ span: 4 }" :wrapper-col="{ span: 14 }" :rules="rules"
              ref="businessRuleForm">

              <a-form-model-item style="width: 1000px; margin: 15px auto;" label="年度税收(万元)" prop="annual_tax">
                <a-input v-model="form.annual_tax" type="number" placeholder="请输入年度税收" :disabled="isLook()" />
              </a-form-model-item>
              <a-form-model-item style="width: 1000px; margin: 15px auto;" label="年收入(万元)" prop="annual_income">
                <a-input v-model="form.annual_income" type="number" placeholder="请输入年收入" :disabled="isLook()" />
              </a-form-model-item>
              <a-form-model-item style="width: 1000px; margin: 15px auto;" label="融资情况">
                <a-select v-model="form.trade_info" placeholder="请选择" :disabled="isLook()"
                  :getPopupContainer="(triggerNode) => triggerNode.parentNode">
                  <a-select-option v-for="(item, index) in tradeInfoData" :key="index" :value="item.value">
                    {{ item.label }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>

              <a-form-model-item style="width: 1000px; margin: 15px auto;" label="企业资质情况"
                prop="qualification_condition">
                <a-checkbox-group @change="selectedEnterpriseCertification" :value="form.qualification_condition"
                  :disabled="isLook()">
                  <div>
                    <a-checkbox value="1">
                      瞪羚企业
                    </a-checkbox>
                    <a-checkbox value="2">
                      中关村高新技术企业
                    </a-checkbox>
                    <a-checkbox value="3">
                      国家高新技术企业
                    </a-checkbox>
                  </div>
                  <div>
                    <a-checkbox value="4">
                      国家级实验室
                    </a-checkbox>
                    <a-checkbox value="5">
                      院士工作站
                    </a-checkbox>
                    <a-checkbox value="6">
                      博士工作站
                    </a-checkbox>
                  </div>
                  <div>
                    <a-checkbox value="100">
                      其他资质
                    </a-checkbox>
                    <div class="certification-container" v-if="showOtherCertificationInput">
                      <a-input placeholder="请输入资质名称" v-model="otherCertification" v-if="!isLook()"
                        :maxLength="50"></a-input>
                      <div class="add-button" v-if="!isLook()">
                        <a-button type="primary" icon="plus" @click="addCertification">
                          新增
                        </a-button>
                      </div>
                      <div v-for="(item, index) in form.other_qualification_list" :key="index">
                        <div style="display: flex; justify-content: space-between; align-items: center;">
                          <span>{{ item }}</span>
                          <a-button type="link" style="color: red; transform: translateX(100%);"
                            @click="deleteCertification(index)" v-if="!isLook()">删除</a-button>
                        </div>
                      </div>
                    </div>
                  </div>
                </a-checkbox-group>
              </a-form-model-item>
            </a-form-model>

            <a-form-model :model="form" :rules="rules" :label-col="{ span: 4 }" :wrapper-col="{ span: 16 }">
              <a-form-model-item style="width: 1000px; margin: 15px auto;" label="企业人才与知识产权">
                <div style="border:1px solid #cccccc;padding-bottom: 20px;">
                  <div
                    style="display: flex;background-color: #e8e8e8;height: 50px;align-items: center;justify-content: space-between;">
                    <div style="width: 50%;text-align: center;">企业人才</div>
                    <div style="width: 50%;text-align: center;">知识产权</div>
                  </div>
                  <div style="display: flex;justify-content: space-between;flex-wrap: wrap;">
                    <div>
                      <div style="display: flex;margin-top: 15px;">
                        <span style="width: 120px;text-align: right;">总人数：</span>
                        <el-input style="width: 150px;" type="number" placeholder="请输入人数"
                          v-model.number="businessFrom.employNum" :disabled="isLook()">
                        </el-input>
                      </div>
                      <div style="display: flex;margin-top: 15px;">
                        <span style="width: 120px;text-align: right;">中方员工数：</span>
                        <el-input style="width: 150px;" type="number" placeholder="请输入人数"
                          v-model.number="businessFrom.chinese_num" :disabled="isLook()">
                        </el-input>
                      </div>
                      <div style="display: flex;margin-top: 15px;">
                        <span style="width: 120px;text-align: right;">外籍员工数：</span>
                        <el-input style="width: 150px;" type="number" placeholder="请输入人数"
                          v-model.number="businessFrom.foreigners_num" :disabled="isLook()">
                        </el-input>
                      </div>
                      <div style="display: flex;margin-top: 20px;">
                        <span style="width: 120px;text-align: right;">本科人数：</span>
                        <el-input style="width: 150px;" placeholder="请输入人数" type="number"
                          v-model.number="businessFrom.graduateNum" :disabled="isLook()"></el-input>
                      </div>
                      <div style="display: flex;margin-top: 20px;">
                        <span style="width: 120px;text-align: right;">硕士人数：</span>
                        <el-input style="width: 150px;" type="number" placeholder="请输入人数"
                          v-model.number="businessFrom.masterNum" :disabled="isLook()">
                        </el-input>
                      </div>
                      <div style="display: flex;margin-top: 20px;">
                        <span style="width: 120px;text-align: right;">博士人数：</span>
                        <el-input style="width: 150px;" type="number" placeholder="请输入人数"
                          v-model.number="businessFrom.doctorNum" :disabled="isLook()">
                        </el-input>
                      </div>
                      <div style="display: flex;margin-top: 20px;">
                        <span style="width: 120px;text-align: right;">留学生人数：</span>
                        <el-input style="width: 150px;" type="number" placeholder="请输入人数"
                          v-model.number="businessFrom.abroadStuNum" :disabled="isLook()"></el-input>
                      </div>
                    </div>
                    <div style="margin-right: 20px;">
                      <div style="display: flex;margin-top: 20px;">
                        <span style="width:170px;text-align: right;">发明专利（已授权）：</span>
                        <el-input style="width: 150px;" placeholder="请输入"
                          v-model.number="intellectualProperty.invention_auth" :disabled="isLook()">
                        </el-input>
                      </div>

                      <div style="display: flex;margin-top: 20px;">
                        <span style="width:170px;text-align: right;">实用新型专利：</span>
                        <el-input style="width: 150px;" type="text" placeholder="请输入"
                          v-model.number="intellectualProperty.utility_model" :disabled="isLook()"></el-input>
                      </div>

                      <div style="display: flex;margin-top: 20px;">
                        <span style="width:170px;text-align: right;">软件著作权：</span>
                        <el-input style="width: 150px;" type="text" placeholder="请输入"
                          v-model.number="intellectualProperty.soft_copyright" :disabled="isLook()"></el-input>
                      </div>

                      <div style="display: flex;margin-top: 20px;">
                        <span style="width:170px;text-align: right;">商标数量：</span>
                        <el-input style="width: 150px;" type="text" placeholder="请输入"
                          v-model.number="intellectualProperty.brand_num" :disabled="isLook()"></el-input>
                      </div>

                      <div style="display: flex;margin-top: 20px;">
                        <span style="width:170px;text-align: right;">发明专利（已发布）：</span>
                        <el-input style="width: 150px;" type="text" placeholder="请输入"
                          v-model.number="intellectualProperty.invention_rel" :disabled="isLook()"></el-input>
                      </div>
                      <div style="display: flex;margin-top: 20px;">
                        <span style="width:170px;text-align: right;">外观专利：</span>
                        <el-input style="width: 150px;" type="text" placeholder="请输入"
                          v-model.number="intellectualProperty.app_patent" :disabled="isLook()"></el-input>
                      </div>

                    </div>
                  </div>
                </div>
              </a-form-model-item>
            </a-form-model>
          </a-tab-pane>
          <a-tab-pane key="5" v-if="isLook() && EnterpriseSyncInfo" tab="企业经营信息">
            <EnterpriseSyncInfo :id="id" :EnterpriseSyncInfo="EnterpriseSyncInfo" />
          </a-tab-pane>
        </a-tabs>
      </div>

      <div v-if="!isLook()" class="button-box">
        <a-button type="primary" @click="onSubmit"> 提交 </a-button>
        <!-- <a-button type="danger" @click="resetForm" class="m-10"> 清空 </a-button> -->
      </div>
    </a-spin>
  </div>
</template>

<script>
import * as api from '@/api/enterprise/enterprise'
import { IMG_API } from '@/config'
import moment from 'moment'
import { REQUEST_CODE } from '@/constant/status'
import EnterpriseSyncInfo from './EnterpriseSyncInfo.vue'
import {
  plainOptions,
  rules,
  joinPorjectcolumns,
  leasePorjectcolumns,
  requireControlMap,
  onlyTakeExistingKeys,
  createEditor
} from './help'
const defaultFormData = {
  enterprise_name: '', // 企业名称
  enterprise_type: undefined, // 企业类型
  us_code: '', // 社会统一信用代码
  // enter_type1: undefined, // 企业类型1级
  // enter_type2: undefined, // 企业类型2级
  established_t: '', // 成立时间
  regis_capital: '', // 注册资本
  regis_addr: '', // 注册地址
  enter_profile: '', // 企业简介
  enter_link_person: '', // 联系人名称
  phone: '', // 联系人联系方式
  legal_person_info: '', // 法人名称
  legal_person_phone: '', // 法人联系方式
  enterprise_logo_url: '', // 企业logo
  business_license: '', // 营业执照
  id_card_portrait: '', // 身份证(人像面)
  id_card_national: '', // 身份证(国徽面)
  annual_tax: '', // 年度税收
  annual_income: '', // 年收入
  trade_info: undefined, // 融资类型
  is_gazelle_ent: 1, // 是否为瞪羚企业（0 否 1 是）
  is_laboratory: 1, // 是否为实验室（0 否 1 是）
  is_high_enter: 1, // 是否为国家高新企业（0 否 1 是）
  enterprise_nature: [], // 企业性质
  social_organization: '', // 社会组织内弄
  nature_other: '', //企业性质(其他)内容
  enterprise_telephone: '', // 企业联系电话(固话)
  enterprise_fax: '', // 企业传真
  enterprise_mail_box: '', // 企业邮箱
  enterprise_remark: '', // 企业其他信息
  qualification_condition: [], // 企业资质情况
  other_qualification_list: [], // 其他资质情况
  enterpriseIdList: [],// 企业关联id
  industry_id: '',// 企业类型id
  is_in_incubator: 0,// 是否在孵化器
}

const defaultBusinessFrom = {
  employNum: undefined, // 从业人数（总数）
  chinese_num: undefined, // 中方员工数
  foreigners_num: undefined, // 外籍员工数
  graduateNum: undefined, // 本科人数
  masterNum: undefined, // 硕士人数
  doctorNum: undefined, // 博士人数
  abroadStuNum: undefined // 留学生人数
}

const defaultIntellectualProperty = {
  soft_copyright: undefined, // 软件著作权
  invention_auth: undefined, // 发明专利（已授权）
  invention_rel: undefined, // 发明专利（已公布）
  app_patent: undefined, // 外观专利
  utility_model: undefined, // 实用新型
  brand_num: undefined, // 商标数量
}

const defaultJoinForm = {
  end_at: '', // 入驻结束时间
  start_at: '', // 入驻开始时间
  user_id: '', // 企服管家id
  user_name: '', // 企服管家名称
  project_id: '', // 项目id
  project_name: '', // 项目名称
  show_end_at: '', // 显示入驻结束时间
  show_start_at: '', // 显示入驻开始时间
  space_id: '', // 空间id
  space_name: '', // 空间名称
  projectName: '',
  parent_name_string: '',
  joinProjectDate: [], // 入驻项目日期, 该字段只在前端使用
  selectIds: [], // 入驻空间IDs, 该字段只在前端使用
  address_id: undefined, // 入驻空间该字段只在前端使用
  selectNames: '', // 入驻空间names, 该字段只在前端使用
  mixin_id: '' // 主键
}

export default {
  name: 'add',
  components: {
    EnterpriseSyncInfo
  },
  computed: {
    showSocietyOrganizationInput() {
      if (this.form.enterprise_nature && this.form.enterprise_nature.length) {
        const enterpriseTypeStr = this.form.enterprise_nature.join(',');
        if (enterpriseTypeStr.includes('100')) {
          return true;
        }
        return false;
      }
      return false;
    },
    showOtherInput() {
      if (this.form.enterprise_nature && this.form.enterprise_nature.length) {
        const enterpriseTypeStr = this.form.enterprise_nature.join(',');
        if (enterpriseTypeStr.includes('101')) {
          return true;
        }
        return false;
      }
      return false;
    },
    showOtherCertificationInput() {
      if (this.form.qualification_condition && this.form.qualification_condition.length) {
        const qiyezizhiqingkuangStr = this.form.qualification_condition.join(',');
        if (qiyezizhiqingkuangStr.includes('100')) {
          return true;
        }
        this.form.other_qualification_list = [];
        return false;
      }
      this.form.other_qualification_list = [];
      return false;
    },
  },
  data() {
    return {
      activeKey: '1',
      editor: null,
      spinning: false,
      IMG_API: IMG_API,
      plainOptions,
      loading: false,
      title: '',
      form: {
        ...defaultFormData

        // 显示营业执照
        // show_business_license: [],
        // 显示身份证(人像面)
        // show_id_card_portrait: [],
        // 显示身份证(国徽面)
        // show_id_card_national: [],
      },
      // 是否隐藏企业logo上传按钮
      isHideLogo: false,
      // 是否隐藏营业执照上传按钮
      isHideBusiness: false,
      // 是否隐藏身份证(人面像)上传按钮
      isHidePortrait: false,
      // 是否隐藏身份证(国徽像)上传按钮
      isHideNational: false,
      rules, // 取自 help
      data: [],
      enterpriseTypes: [], // 企业类型
      enterType1Data: [], // 行业一级分类
      enterType2Data: {}, // 行业二级分类
      showEnterType2Data: [], // 显示企业类型2数据
      tradeInfoData: [], // 融资类型

      // 入驻信息form
      joinForm: defaultJoinForm,
      joinPorjectData: [], // 入驻项目信息
      editjoinPorjectData: [], // 编辑进入入驻项目信息
      leasePorjectData: [], // 退租信息
      projectData: [], // 可入驻项目
      commissionerData: [], // 企服管家测试数据
      joinPorjectcolumns, // 取自 help
      leasePorjectcolumns, // 取自 help

      // 经营信息form
      businessFrom: { ...defaultBusinessFrom },
      // 知识产权form
      intellectualProperty: { ...defaultIntellectualProperty },
      // 企业人才
      testData: [{}],

      // 编辑状态 0.添加, 1.编辑, 2.查看
      editState: '',
      // 项目id
      id: '',
      // 是否禁用
      isDisbaled: false,
      // 入驻空间
      options: [],
      address: [],
      // 自定义 options 中 label name children 的字段
      fieldNames: {
        label: 'project_name',
        value: 'id',
        children: 'space_list'
      },
      projectId: '',
      showModal: false,
      modalTitle: '', // 弹框类型，用于区分 add / edit
      // 
      props: {
        multiple: true,
        label: 'project_name',
        value: 'id',
        children: 'space_list',
        checkStrictly: true
      },
      otherCertification: '',
      enterpriseList: [], // 关联企业列表
      EnterpriseSyncInfo: {},// 企业同步信息
      industryTree: [],// 行业分类
      fieldNames2: { label: 'industry_name', value: 'industry_id', children: 'child_list' },
      parent_industry_id_list: [],
      moment
    }
  },
  methods: {
    callback(key) {
      console.log(key);
    },
    onSubmit() {
      if (this.isDisbaled) return
      this.isDisbaled = true
      this.$refs.ruleForm.validate(valid => {
        if (!valid) {
          this.$message.error('请检查必填信息')
          this.activeKey = '1'
          this.isDisbaled = false
          return
        }
        // this.$refs.businessRuleForm.validate(valid1 => {
        // 如果 joinProjectDate && user_id 必填
        if (this.editjoinPorjectData.length > 0 && this.joinPorjectData.length === 0) {

        } else {
          // if (
          //   this.rules.joinProjectDate[0].required &&
          //   this.rules.user_id[0].required &&
          //   !this.joinPorjectData.length
          // ) {
          //   this.$message.error('请填写入驻信息')
          //   this.isDisbaled = false
          //   this.activeKey = '2'
          //   return
          // }
          if (!this.joinPorjectData.length && this.form.enterpriseIdList.length > 0) {
            this.isDisbaled = false
            this.$message.error('此企业无入驻信息，无法关联其他企业')
            return
          }
          if (
            this.rules.joinProjectDate[0].required &&
            this.rules.user_id[0].required &&
            this.joinPorjectData.some(
              item => !item.start_at || !item.end_at || !item.user_id
            )
          ) {
            this.$message.error('请补全入驻信息')
            this.isDisbaled = false
            this.activeKey = '2'
            return
          }
        }

        // if (!valid1) {
        //   this.$message.error('请检查经营信息')
        //   this.isDisbaled = false
        //   this.activeKey = '3'
        //   return
        // }
        if (this.editState === '1') {
          this.editEnter()
        } else {
          this.addEnter()
        }
        // api.addGroup({enterprise_id_list:this.form.enterpriseIdList}).then(res=>{
        //   console.log(res,'================>');
        // })
        // })
      })
    },
    /** 基本信息操作 */
    // 上传企业logo
    uploadEnterLogo(v) {
      if (v.file.status === 'uploading') {
        this.isHideLogo = true
      } else {
        this.isHideLogo = false
      }
      if (v.fileList.length === 0) {
        this.form.enterprise_logo_url = ''
        return
      }
      if (v.file.response) {
        this.form.enterprise_logo_url = v.file.response.redirect_uri
      }
    },
    // 选中企业性质
    selectedEnterpriseType(e) {
      this.form.enterprise_nature = e;
    },
    // 选中企业资质
    selectedEnterpriseCertification(e) {
      this.form.qualification_condition = e;
    },
    // 添加其他资质
    addCertification() {
      if (this.form.other_qualification_list.length === 10) return;
      if (!this.otherCertification) {
        this.$message.warning('请输入其他资质名称');
        return;
      }
      this.form.other_qualification_list.push(this.otherCertification);
      this.otherCertification = '';
    },
    // 删除其他资质
    deleteCertification(index) {
      this.form.other_qualification_list = this.form.other_qualification_list.filter((item, itemIndex) => itemIndex !== index);
      this.$forceUpdate();
    },
    // 上传营业执照
    uploadBusinessLicense(v) {
      if (v.file.status === 'uploading') {
        this.isHideBusiness = true
      } else {
        this.isHideBusiness = false
      }
      this.form.show_business_license = v.fileList
      if (v.fileList.length === 0) {
        this.form.business_license = ''
        return
      }
      if (v.file.response) {
        this.form.business_license = v.file.response.redirect_uri
      }
    },
    // 上传身份证(人像面)
    uploadIdCardPortrait(v) {
      if (v.file.status === 'uploading') {
        this.isHidePortrait = true
      } else {
        this.isHidePortrait = false
      }
      this.form.show_id_card_portrait = v.fileList
      if (v.fileList.length === 0) {
        this.form.id_card_portrait = ''
        return
      }
      if (v.file.response) {
        this.form.id_card_portrait = v.file.response.redirect_uri
      }
    },
    // 上传身份证(国徽面)
    uploadIdCardNational(v) {
      if (v.file.status === 'uploading') {
        this.isHideNational = true
      } else {
        this.isHideNational = false
      }
      this.form.show_id_card_national = v.fileList
      if (v.fileList.length === 0) {
        this.form.id_card_national = ''
        return
      }
      if (v.file.response) {
        this.form.id_card_national = v.file.response.redirect_uri
      }
    },
    // 删除logo
    removeLogo() {
      this.form.enterprise_logo_url = ''
    },
    // 删除营业执照
    removeBusinessLicense() {
      this.form.business_license = ''
      this.form.show_business_license = []
    },
    // 删除身份证(人面像)
    removeIdCardPortrait() {
      this.form.id_card_portrait = ''
      this.form.show_id_card_portrait = []
    },
    // 删除身份证(国徽面)
    removeIdCardNational() {
      this.form.id_card_national = ''
      this.form.show_id_card_national = []
    },
    // 上传图片前校验
    beforeUpload(file) {
      const isJpgOrPng =
        file.type === 'image/jpeg' ||
        file.type === 'image/png' ||
        file.type === 'image/jpg'
      if (!isJpgOrPng) {
        this.$message.error('请上传jpeg或png格式')
      }
      return isJpgOrPng
    },
    // 选择企业类型
    handleEnterpriseTypeChange(val) {
      // 改变前先初始化
      if (!val) {
        val = 'initial'
      }
      requireControlMap.initial.forEach(fieldName => {
        this.rules[fieldName][0].required = false
      })
      requireControlMap[val].forEach(fieldName => {
        this.rules[fieldName][0].required = true
      })
    },
    // 选择行业一级分类
    selectEnterType1(v) {
      // 动态调整 行业二级分类
      this.showEnterType2Data = this.enterType2Data[v]
      if (this.showEnterType2Data.length > 0) {
        this.form.enter_type2 = undefined
      } else {
        this.form.enter_type2 = '无'
      }
    },

    // 选择入驻有效期
    selectedJoinDate(v) {
      console.log(v[0]);
      this.joinForm.start_at = v[0]
      this.joinForm.end_at = v[1]
      this.joinForm.show_start_at = moment(v[0]).format('YYYY-MM-DD')
      this.joinForm.show_end_at = moment(v[1]).format('YYYY-MM-DD')
    },
    // 选择管家
    handleSelect(v) {
      const butler = this.commissionerData.filter(item => {
        if (item.user_id === v) return item
      })[0]
      console.log(butler,'========');
      
      this.joinForm.user_id = butler.user_id
      this.joinForm.user_name = butler.name
    },
    // 选择地址
    handleSelectAddress(v) {
      this.options = this.address.filter(item => {
        return item.id === v
      })
      console.log(this.options);
      let ary = []
      this.editjoinPorjectData.forEach(item => {
        ary.push(item.space_id)
      })
      this.joinPorjectData.forEach(item => {
        ary.push(item.space_id)
      })
      if (ary.includes(this.options[0].id)) {
        this.options[0].disabled = true
      }
      this.recursion(this.options[0], ary)
      console.log(this.options[0]);
      for (let i = 0; i < this.options.length; i++) {
        const item = this.options[i];
      }
      this.joinForm.user_id = ''
      this.projectId = this.joinForm.address_id
      this.getCommissionerByProjectId()
    },
    recursion(options, ary) {
      console.log(options);
      if (options.space_list) {
        if (options.space_list.length > 0) {
          for (let i = 0; i < options.space_list.length; i++) {
            if (ary.includes(options.space_list[i].id)) {
              options.space_list[i].disabled = true
            }
            this.recursion(options.space_list[i], ary)
          }
        }
      }

    },
    handleCancel() {
      this.$refs.joinForm.clearValidate()
    },
    // 确认加入
    confirmJoin() {
      const { space_id, start_at, end_at, user_id, project_id } = this.joinForm
      const mixin_id = space_id || project_id
      if (this.rules.joinProjectDate[0].required && (!start_at || !end_at)) {
        return this.$message.info('请输入入驻日期')
      }
      if (this.rules.user_id[0].required && !user_id) {
        return this.$message.info('请选择企服管家')
      }
      console.log(space_id, project_id);
      // 处理 新增
      if (this.modalTitle === '新增') {
        if (!mixin_id) {
          return this.$message.info('请选择要加入的项目')
        }
        // 已处理唯一校验
        if (
          this.joinPorjectData.some(item =>
            [item.project_id, item.mixin_id, item.space_id].includes(mixin_id)
          )
        ) {
          return this.$message.error('同一公司只能入驻一次')
        }
        let arr = [...this.joinPorjectData, ...this.editjoinPorjectData]
        for (let i = 0; i < arr.length; i++) {
          const item = arr[i];
          if (mixin_id instanceof Array) {
            for (let j = 0; j < mixin_id.length; j++) {
              const itm = mixin_id[j];
              if ([item.project_id, item.mixin_id, item.space_id].includes(itm[itm.length - 1])) {
                return this.$message.error('同一公司只能入驻一次')
              }
            }
          }
        }

        this.joinForm.project_name.forEach((item, index) => {
          this.joinPorjectData.push({
            ...this.joinForm,
            project_name: item,
            space_id: this.joinForm.space_id[index][this.joinForm.space_id[index].length - 1],
            projectName: this.joinForm.projectName[index],
            space_name: this.joinForm.space_name[index],
            parent_name_string: this.joinForm.parent_name_string[index]
          })
        })
        // this.joinPorjectData.push({ ...this.joinForm })
        console.log(this.joinPorjectData, '...this.joinForm')
      } else {
        // 处理 编辑
        this.joinPorjectData.forEach(item => {
          if (item.mixin_id === this.joinForm.mixin_id) {
            onlyTakeExistingKeys(item, this.joinForm)
          }
        })
        this.editjoinPorjectData.forEach(item => {
          if (item.mixin_id === this.joinForm.mixin_id) {
            onlyTakeExistingKeys(item, this.joinForm)
          }
        })
      }
      // 清空表单，方便下次使用
      this.joinForm = { ...defaultJoinForm }
      this.$refs.joinForm.clearValidate()
      // 关闭弹框
      this.showModal = false
    },
    // 删除数据
    onDelete(v) {
      const joinPorjectData = this.joinPorjectData.filter(item => {
        if (item.space_id !== v.space_id) return item
      })
      this.joinPorjectData = joinPorjectData
    },
    // 查看时 为 true ，新增和编辑 为 false
    isLook() {
      if (this.editState === '2') return true
      return false
    },

    // 唤起弹框
    async evokeModal(modalTitle, record) {
      this.modalTitle = modalTitle
      // 默认清空表单 再使用
      this.joinForm = { ...defaultJoinForm }
      if (modalTitle === '编辑') {
        // 处理反显
        this.joinForm.selectNames = `${record.project_name}${record.parent_name_string ? '/' + record.parent_name_string : ''
          }${record.space_name ? '/' + record.space_name : ''}`
        this.joinForm.start_at = record.start_at
        this.joinForm.end_at = record.end_at
        this.joinForm.joinProjectDate =
          record.start_at && record.end_at
            ? [moment(record.start_at), moment(record.end_at)]
            : []
        this.projectId = record.project_id
        this.joinForm.space_id = record.space_id
        this.joinForm.mixin_id = record.mixin_id
        await this.getCommissionerByProjectId()
        if (this.commissionerData.some(item => item.id === record.user_id)) {
          this.joinForm.user_id = record.user_id
        } else {
          this.joinForm.user_id = ''
        }
        this.showModal = true
      } else {
        this.showModal = true
      }
    },
    /** 网络请求 */
    // 获取企业类型
    async getEnterpriseType() {
      try {
        const result = await api.getEnterpriseType()
        // 注意 该接口 的响应结构
        this.enterpriseTypes = result.data
      } catch (error) {
        console.log(error, '==================>');
      }
    },
    // 获取行业一级分类
    async getEnterTypeOne() {
      try {
        const result = await api.getEnterType1()
        this.enterType1Data = result.data
      } catch (error) { }
    },
    // 获取行业二级分类
    async getEnterTypeTwo() {
      try {
        const result = await api.getEnterType2()
        this.enterType2Data = result.data
      } catch (error) { }
    },
    // 获取融资类型
    async getTradeInfo() {
      try {
        const result = await api.getTradeInfo()
        this.tradeInfoData = result.data
        // this.form.trade_info = this.tradeInfoData[0].value;
      } catch (error) { }
    },
    // 获取可入驻项目
    // 获取企服管家列表
    async getCommissionerByProjectId() {
      try {
        const result = await api.getCommissionerByProjectId(this.projectId)
        if (result.data.code === REQUEST_CODE) {
          this.commissionerData = result.data.data
        }
      } catch (error) { }
    },
    // 添加企业
    async addEnter() {
      if (this.joinForm.project_id) {
        if (!this.joinForm.start_at || !this.joinForm.end_at) {
          this.$message.info('请输入入驻日期')
          this.isDisbaled = false
          return
        }
      }
      const qualification_condition = this.form.qualification_condition.join(',');
      if (qualification_condition.includes('100') && !this.form.other_qualification_list.length) {
        this.$message.warning('请输入其他资质名称');
        this.isDisbaled = false
        return;
      }
      try {
        this.spinning = true
        const data = this.tidySubmitData()
        const result = await api.addEnter(data)
        this.spinning = false
        if (result.data) {
          this.$message.success('添加成功')
          this.goBack()
        } else {
          this.$message.error(result.message)
        }
      } catch (error) {
        this.$message.error(error.data.error)
        this.spinning = false
        this.isDisbaled = false
      }
    },
    // 获取企业详情
    async getEnterDetail() {
      try {
        this.spinning = true
        const result = await api.getEnterDetailById(this.id)
        console.log('[[[[[]]]]]', result);

        if (result.data.data) {
          this.spinning = false
          this.tidyGetData(result.data.data)
          // 回显 富文本内容
          this.editor.txt.html(this.form.enter_profile)
        }
      } catch (error) {
        this.spinning = false
      }
    },
    // 编辑企业
    async editEnter() {
      if (this.joinForm.project_id) {
        if (!this.joinForm.start_at || !this.joinForm.end_at) {
          this.$message.info('请输入入驻日期')
          this.isDisbaled = false
          return
        }
      }
      const qualification_condition = this.form.qualification_condition.join(',');
      if (qualification_condition.includes('100') && !this.form.other_qualification_list.length) {
        this.$message.warning('请输入其他资质名称');
        this.isDisbaled = false
        return;
      }
      try {
        this.spinning = true
        const data = this.tidySubmitData()
        const result = await api.editEnter(data, this.id)
        this.spinning = false
        console.log(result,'result<<<<<<<<<<<<<<');
        
        if (result.data) {
          this.$message.success('修改成功')
          this.goBack()
        } else {
          this.$message.error(result.data.msg)
        }
      } catch (error) {
        this.$message.error(error)
        this.spinning = false
        this.isDisbaled = false
      }
    },
    /** 数据处理 */
    // 处理提交企业数据
    tidySubmitData() {
      let submitData = {}
      submitData.is_in_incubator = this.form.is_in_incubator
      submitData.industry_id = this.form.industry_id
      submitData.enterprise_name = this.form.enterprise_name
      submitData.enterprise_type = this.form.enterprise_type // 新增 字段
      submitData.us_code = this.form.us_code.toUpperCase()
      submitData.enterprise_desc = {
        enter_type1: this.form.enter_type1,
        enter_type2: this.form.enter_type2,
        established_t: this.form.established_t,
        enter_profile: this.form.enter_profile,
        regis_capital: this.form.regis_capital,
        regis_addr: this.form.regis_addr,
        legal_person_info: this.form.legal_person_info,
        legal_person_phone: this.form.legal_person_phone,
        enter_link_person: this.form.enter_link_person,
        phone: this.form.phone,
        enterprise_logo_url: this.form.enterprise_logo_url,
        business_license: this.form.business_license,
        id_card_portrait: this.form.id_card_portrait,
        id_card_national: this.form.id_card_national,
        annual_tax: this.form.annual_tax,
        annual_income: this.form.annual_income,
        trade_info: this.form.trade_info,
        is_gazelle_ent: this.form.is_gazelle_ent,
        is_laboratory: this.form.is_laboratory,
        is_high_enter: this.form.is_high_enter,
        employNum: this.businessFrom.employNum,
        graduateNum: this.businessFrom.graduateNum,
        masterNum: this.businessFrom.masterNum,
        doctorNum: this.businessFrom.doctorNum,
        abroadStuNum: this.businessFrom.abroadStuNum,
        soft_copyright: this.intellectualProperty.soft_copyright,
        invention_auth: this.intellectualProperty.invention_auth,
        invention_rel: this.intellectualProperty.invention_rel,
        app_patent: this.intellectualProperty.app_patent,
        utility_model: this.intellectualProperty.utility_model,
        enterprise_nature: this.form.enterprise_nature,
        social_organization: this.form.social_organization,
        nature_other: this.form.nature_other,
        enterprise_telephone: this.form.enterprise_telephone,
        enterprise_fax: this.form.enterprise_fax,
        enterprise_mail_box: this.form.enterprise_mail_box,
        enterprise_remark: this.form.enterprise_remark,
        qualification_condition: this.form.qualification_condition,
        other_qualification_list: this.form.other_qualification_list,
        chinese_num: this.businessFrom.chinese_num,
        foreigners_num: this.businessFrom.foreigners_num,
        brand_num: this.intellectualProperty.brand_num
      }
      const projects = []
      let copyList = JSON.parse(JSON.stringify([...this.editjoinPorjectData, ...this.joinPorjectData]))
      copyList.forEach(item => {
        item.project_name = item.projectName
        item.project_id = item.space_id || item.project_id
        let project = { ...item }
        projects.push(project)
      })
      submitData.projects = projects
      submitData.group_enterprise_id_list = this.form.enterpriseIdList

      console.log(submitData, '===========');
      return submitData
    },
    // 处理获取企业数据
    tidyGetData(data) {
      // 从详情获取的数据 分别 塞进 不同的 data 字段中 用于 反显
      let {
        enterprise_desc,
        enterprise_name,
        enterprise_type, // 新增 字段
        us_code,
        projects,
        lease_projects = []
      } = data
      enterprise_desc = enterprise_desc || {}
      // 初始化校验规则
      this.handleEnterpriseTypeChange(enterprise_type)
      const enterprise_logo_url = enterprise_desc.enterprise_logo_url || ''
      const business_license = enterprise_desc.business_license || ''
      const id_card_portrait = enterprise_desc.id_card_portrait || ''
      const id_card_national = enterprise_desc.id_card_national || ''
      const annual_income = String(enterprise_desc.annual_income)
      this.form = {
        enterprise_name,
        enterprise_type, // 新增 字段
        us_code,
        enter_type1: enterprise_desc.enter_type1,
        enter_type2: enterprise_desc.enter_type2,
        established_t: enterprise_desc.established_t,
        regis_capital: enterprise_desc.regis_capital,
        regis_addr: enterprise_desc.regis_addr,
        enter_profile: enterprise_desc.enter_profile,
        enter_link_person: enterprise_desc.enter_link_person,
        phone: enterprise_desc.phone,
        legal_person_info: enterprise_desc.legal_person_info,
        legal_person_phone: enterprise_desc.legal_person_phone,
        enterprise_logo_url,
        business_license,
        id_card_portrait,
        id_card_national,
        annual_tax: enterprise_desc.annual_tax,
        annual_income: annual_income,
        trade_info: enterprise_desc.trade_info,
        is_gazelle_ent: enterprise_desc.is_gazelle_ent,
        is_laboratory: enterprise_desc.is_laboratory,
        is_high_enter: enterprise_desc.is_high_enter,
        enterprise_nature: enterprise_desc.enterprise_nature || [],
        social_organization: enterprise_desc.social_organization || '',
        nature_other: enterprise_desc.nature_other || '',
        enterprise_telephone: enterprise_desc.enterprise_telephone || '',
        enterprise_fax: enterprise_desc.enterprise_fax || '',
        enterprise_mail_box: enterprise_desc.enterprise_mail_box || '',
        enterprise_remark: enterprise_desc.enterprise_remark || '',
        qualification_condition: enterprise_desc.qualification_condition || [],
        enterpriseIdList: [],
        is_in_incubator: data.is_in_incubator,
        industry_id: data.industry_id
      }
      this.form.other_qualification_list = enterprise_desc.other_qualification_list || [];
      this.parent_industry_id_list = data.parent_industry_id_list
      let arr = []
      const tidyItem = item => ({
        end_at: item.end_at,
        projectName: item.space_name ? item.space_name : item.project_name,
        project_id: item.project_id, // project值准确
        space_id: item.space_id,
        mixin_id: item.space_id ? item.space_id : item.project_id,
        project_name: item.project_name,
        show_end_at: item.end_at
          ? moment(item.end_at).format('YYYY-MM-DD')
          : '',
        show_start_at: item.start_at
          ? moment(item.start_at).format('YYYY-MM-DD')
          : '',
        space_name: item.space_name,
        start_at: item.start_at,
        user_id: item.user_id,
        user_name: item.user_name,
        parent_name_string: item.parent_name_string
      })

      projects.forEach(item => {
        const temp = tidyItem(item)
        arr.push(temp)
      })
      // 用于渲染表格
      // this.joinPorjectData = arr
      this.editjoinPorjectData = arr

      // 处理 退租表格信息
      let leaseArr = []
      if (lease_projects) {
        lease_projects.forEach(item => {
          let temp = tidyItem(item)
          // 需要额外处理的字段
          temp.lease_id = item.lease_id
          temp.lease_id_show = item.lease_id_show
          temp.gmt_lease = item.gmt_lease
            ? moment(item.gmt_lease).format('YYYY-MM-DD HH:mm:ss')
            : ''
          leaseArr.push(temp)
        })
        this.leasePorjectData = leaseArr
      }

      // 将 enterprise_desc 的值 赋值给 this.businessFrom 对应的key
      onlyTakeExistingKeys(this.businessFrom, enterprise_desc)

      onlyTakeExistingKeys(this.intellectualProperty, enterprise_desc)
    },
    // 清空数据
    resetForm() {
      this.form = { ...defaultFormData }
      this.businessFrom = { ...defaultBusinessFrom }
      this.intellectualProperty = { ...defaultIntellectualProperty }
      this.joinForm = { ...defaultJoinForm }
      this.joinPorjectData = []
      this.$refs.ruleForm.resetFields()
      console.log('================')
    },
    // 入驻空间
    // onSpaceChange(value, item) {
    //   let nodesObj = this.$refs['cascader'].getCheckedNodes()
    //   console.log('===>val', item)
    //   console.log('===>nodesObj', nodesObj)
    //   if (!item) return
    //   this.joinForm.space_id = value[value.length - 1]
    //   this.projectId = value[0]
    //   this.joinForm.project_id = value[0]
    //   this.joinForm.project_name = item[0].project_name
    //   let tempList = item.slice(1, item.length - 1)
    //   let str = ''
    //   tempList.forEach(val => {
    //     str = str + '/' + val.project_name
    //   })
    //   str = str.substring(1)
    //   console.log(str);
    //   this.joinForm.parent_name_string = str
    //   this.joinForm.projectName = item[item.length - 1].project_name
    //   this.joinForm.space_name = item[item.length - 1].project_name
    //   console.log( this.joinForm.space_name);
    //   this.getCommissionerByProjectId()
    //   this.joinForm.user_id = ''
    // },
    onSpaceChange(value, item) {
      let nodesObj = this.$refs['cascader'].getCheckedNodes()
      if (nodesObj.length < 0) return
      console.log('===>val', value)
      console.log('===>nodesObj', nodesObj)
      this.joinForm.space_id = value
      this.projectId = this.joinForm.address_id
      this.joinForm.project_id = this.joinForm.address_id
      let name = []
      let name1 = []
      let name2 = []
      nodesObj.forEach((item, index) => {
        for (let i = 0; i < value.length; i++) {
          const element = value[i];
          if (item.value === element[value[i].length - 1]) {
            name1.push(item.pathLabels[0])
            name2.push(item.pathLabels[item.pathLabels.length - 1])
            let str = ''
            let tempList = item.pathLabels.slice(1, item.pathLabels.length - 1)
            tempList.forEach(itm => {
              str = str + '/' + itm
            })
            str = str.substring(1)
            name.push(str)
          }
        }
      })
      console.log(name2);
      this.joinForm.project_name = name1
      this.joinForm.parent_name_string = name
      this.joinForm.projectName = name2
      this.joinForm.space_name = name2
      // this.joinForm.user_id = ''
    },
    // 获取入驻空间数据
    async getProduct() {
      try {
        const res = await api.findAllSpace()
        this.address = res.data.data
        console.log(res, 'addressaddressaddressaddressaddressaddressaddress============>');
      } catch (error) {
        console.log(error, '============>');
      }
    },
    // 返回
    goBack() {
      this.$router.go(-1)
    },
    async getGroupDown(val) {
      let res = await api.getGroupDown(val, this.id)
      this.enterpriseList = res.data.data
      console.log(res, '=============>');
    },
    setSelectable(arr) {
      arr.forEach(item => {
        if (item.child_list.length > 0) {
          this.setSelectable(item.child_list)
          item.selectable = false
        } else {
          item.child_list = null
        }
      })
    },
    async getindustryTree() {
      try {
        let res = await api.getindustryTree()
        console.log(res, 'res.datares.datares.datares.datares.datares.data============>');
        res.data.forEach(item => {
          if (item.child_list.length > 0) {
            this.setSelectable(item.child_list)
            item.selectable = false
          } else {
            item.child_list = null
          }
        })
        this.industryTree = res.data
      } catch (error) {
        console.log(error, '=============>');
      }
    },
    selectIndustry() {
      this.form.industry_id = this.parent_industry_id_list[this.parent_industry_id_list.length - 1]
    }
  },
  created() {
    console.log('===================================')
    this.id = this.$route.query.id || ''
    this.editState = this.$route.query.editState
    this.title = this.$route.query.title
    this.getEnterpriseType()
    // this.getEnterTypeOne()
    // this.getEnterTypeTwo()
    this.getTradeInfo()
    if (this.editState !== '0') {
      this.getEnterDetail().then(res => {
        this.form.enterpriseIdList = []
        api.getGroup(this.id).then(res => {
          this.enterpriseList = res.data.data
          console.log(this.form, '===================================');
          this.enterpriseList.forEach(item => {
            this.form.enterpriseIdList.push(item.enterprise_id)
          })
        }).catch(error => {
          console.log(error, '=======++++');
        })
      })
      api.getOtherInfoDetail(this.id).then(res => {
        console.log(res, '=====');
        if (res.code == '200') {
        } else {
          // this.$message.error(res.msg)
        }
        this.EnterpriseSyncInfo = res.data

      })
    }
    this.getindustryTree()
    this.getProduct()
  },
  mounted() {
    createEditor(this, '#editer')
    // 查看的时候禁用富文本
    if (this.editState === '2') {
      this.editor.disable()
    } else {
      this.editor.enable()
    }
  },
  beforeDestroy() {
    // 调用销毁 API 对当前编辑器实例进行销毁
    this.editor.destroy()
    this.editor = null
  }
}
</script>
<style lang="scss">
.certification-container {
  margin: 5px 0;
  position: relative;

  .add-button {
    position: absolute;
    top: 0;
    right: 0;
    transform: translateX(110%);
  }
}

.el-table .cell,
.el-table--border td:first-child .cell,
.el-table--border th:first-child .cell {
  color: #000;
  text-align: center;
  font-weight: 700;
}

>>>input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

>>>input[type="number"] {
  -moz-appearance: textfield;
}

.button-box {
  height: 80px;
  display: flex;
  // justify-content: center;
  // margin-left: 90px;
  align-items: center;
}

.info {
  font-size: 20px;
  font-weight: 700;
  // margin-left: 90px;
  margin-bottom: 20px;
  margin-top: 20px;
}

.confirmJoin {
  // margin-left: 100px;
  margin-bottom: 20px;
}

.editor {
  min-width: 460px;
}

.ant-tabs-nav .ant-tabs-tab {
  margin: 0;
}

.add_box .ant-tabs .ant-tabs-top-content>.ant-tabs-tabpane {
  height: calc(100vh - 400px) !important;
  overflow-y: scroll;
}

.add_box .ant-tabs .ant-tabs-top-content>.ant-tabs-tabpane::-webkit-scrollbar {
  display: none !important;
}

.ant-cascader-input.ant-input {
  height: 40px;
}
</style>
